'use client';

import React, { ReactNode, useState } from 'react';
import { USER_SESSION } from '../lib/user';

interface StateContextType {
  user: USER_SESSION | null;
  locale: string;
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<USER_SESSION | null>>;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

type ContextProviderProps = {
  children?: ReactNode;
  initialLoggedIn?: boolean;
  authOpen?: boolean;
  user: USER_SESSION | null;
  locale: string;
};

export const UserContext = React.createContext<StateContextType>({
  user: null,
  loggedIn: false,
  locale: 'en',
  setLoggedIn: () => {},
  setUser: () => {},
  setLocale: () => {},
});

export function UserProvider({ initialLoggedIn, authOpen, children, user }: ContextProviderProps) {
  const [optimisticLoggedIn, setOptimisticLoggedIn] = useState<boolean>(initialLoggedIn ?? false);
  const [optimisticUser, setOptimisticUser] = useState<USER_SESSION | null>(user);
  const [locale, setLocale] = useState<string>('en');

  return (
    <UserContext.Provider
      value={{
        loggedIn: optimisticLoggedIn,
        setLoggedIn: setOptimisticLoggedIn,
        user: optimisticUser,
        setUser: setOptimisticUser,
        locale: locale,
        setLocale: setLocale,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): StateContextType {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}
